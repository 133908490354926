<template>
  <div class='mt-0'>
    <modal :show-modal="showModal && !costBreakdownErrorModalOpen" @close-modal="$emit('close-modal')" :contentClass="'w-50'">
      <template v-slot:header>
        <div class="d-flex justify-content-between align-items-center m-0">
          <h4>{{ confirmationText }}</h4>
          <b-button v-if="canDownloadSimulationSettings" class=mt-0 variant="link" @click="downloadSimulationSettings()">Download Simulation Settings</b-button>
        </div>
      </template>

      <template v-slot:main-content>
        <div v-if='!loadingCostBreakdown'>
          <p class='font-weight-bold' v-if='simulationCreditCost'>
            Your {{ simulationType }} simulation will cost {{ simulationCreditCost }} credits
          </p>
          <p v-if='scenarioCostBreakdown'>
            Your account will be charged as follows.
          </p>
          <div
            v-else
            class='border rounded text-danger border-danger py-2 px-3'
            style='font-size: 0.75rem'>
            <b-icon icon='x'></b-icon>
            You do not have enough credits or a payment plan in place. You may not submit this scenario.
          </div>
          <b-table
            v-if='scenarioCostBreakdown && !loadingCostBreakdown'
            :fields='costBreakdownTableFields'
            :items='costBreakdownTableObject'
            head-variant='light'
            class='border'>
          </b-table>
          <p class='font-weight-bold' v-if='scenarioDollarCost > 0 && !loadingCostBreakdown'>
            Total New Charges: ${{ scenarioDollarCost }}
          </p>
          <div
            class='border rounded text-primary border-primary py-2 px-3'
            style='font-size: 0.75rem;'
            v-if='scenarioDollarCost > 0 && !loadingCostBreakdown'>
            <b-icon icon='info-circle'></b-icon>
            The total amount corresponds to charged credits. You will be billed for them on your next bill.
          </div>
          <FormulateForm :values="formValues" @submit="submitScenario" #default="{ hasErrors, isLoading }">
            <div ref='form_text'>
              <FormulateInput
                class='submit-scenario-checkbox'
                value='agree'
                type='checkbox'
                validation='required'
                validation-name='Agreeing to the terms and conditions'>
                <label
                  slot='label'
                  class='submit-scenario-checkbox-label font-weight-normal'>
                  I acknowledge that I have read the
                  <a href='https://help.orbitalstack.com/terms/' target='_blank'>Orbital Stack End User License Agreement (dated November 1, 2023)</a>
                  and agree to be bound to its terms.  I further acknowledge that I have read and fully understood the contents of Article 3
                  surrounding the Intended Use of the Orbital Stack Software
                </label>
              </FormulateInput>
            </div>
            <hr>
            <div class="submit-container" v-if="canCreateScenario">
              <custom-button theme='ghost-button' type="button" @click="$emit('close-modal')">Cancel</custom-button>
              <div class="submit-button-container">
                <span v-if="isLoading" class="mt-2 mr-2">
                  <b-spinner small variant="primary"></b-spinner>
                </span>  
                <FormulateInput
                  type="submit"
                  :label="'Submit Scenario'"
                  :error="errorSubmittingScenario ? 'Error submitting scenario' : null"
                  :disabled="hasErrors || !scenarioCostBreakdown" />
              </div>
            </div>
          </FormulateForm>
        </div>
        <div v-else class='d-flex justify-content-center no-top-margin-all-descendants'>
          <span class="mr-2">Calculating simulation cost...</span>
          <b-spinner small variant='primary'></b-spinner>
        </div>
      </template>
    </modal>
    <modal :show-modal='costBreakdownErrorModalOpen' :contentClass='"w-25"'>
      <template v-slot:header>
        <h4>Warning</h4>
      </template>
      <template v-slot:main-content>
        <div class='text-center'>
          <b-icon
            style='width: 5rem; height: 5rem;'
            variant='primary'
            icon='exclamation-circle-fill'>
          </b-icon>
        </div>
        <div class='text-center'>
          Someone else on your team has used credits in the account. A new payment breakdown is required.
        </div>
      </template>
      <template v-slot:footer>
        <b-button
          @click='closeErrorModal'
          class='float-right'
          variant='primary'>
          Refresh payment summary
        </b-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Modal, CustomButton } from 'rwdi-widgets';
import {
  ADD_SCENARIO
} from '@/constants/permissions';
import {
  GET_SCENARIO_COST_BREAKDOWN
} from '@/store/actions/permissions';
import fileDownloadService from '@/network/file-downloader';

export default {
  name: 'CreateScenarioTermsAndConditionsModal',
  components: {
    Modal,
    CustomButton
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    importResultJobType() {
      let jobType;
      this.simulation?.job_types_with_criteria?.some(x => {
        jobType =  this.jobTypes?.find(z => z.id === x.job_type);    
      });
      if(jobType?.label == 'Imports GLTF Results')
        return true;
      return false;
    },
    canCreateScenario() {
      return (this.$store.getters['allowedPermissions'] || {})[ADD_SCENARIO];
    },
    confirmationText() {
      return 'Run simulation';
    },
    projectId() {
      return Number(this.$route.params.id);
    },
    studyId() {
      return Number(this.$route.params.study);
    },
    configurationId() {
      return this.$store.getters['project/createdScenarioId'] || this.$store.getters['project/simulationAsset/configurationId'];
    },
    simulation() {
      return this.$store.getters['project/simulationAsset/simulation'];
    },
    simulationType() {
      return this.simulation?.simulation_type === 'ML' ? 'AI' : 'CFD';
    },
    isMLSimulation() {
      return this.simulation?.simulation_type === 'ML';
    },
    isCFDSimulation() {
      return this.simulation?.simulation_type === 'CFD';
    },
    scenarioSubmissionObject() {
      return {
        projectId: Number(this.$route.params.id),
        studyId: Number(this.$route.params.study),
        configurationId: this.configurationId,
        scenario: {
          approval: {
            confirmation: {
              title: this.confirmationText,
              text: this.$refs.form_text.innerText
            },
            approves_submission: true,
            cost_breakdown: this.scenarioCostBreakdown
          }
        }
      };
    },
    importResultsObject() {
      return {
        projectId: Number(this.$route.params.id),
      };
    },
    scenarioCostBreakdownObject() {
      return {
        projectId: this.projectId,
        studyId: this.studyId,
        configurationId: this.configurationId
      };
    },
    simulationCreditCost() {
      let cost = 0;
      if(this.scenarioCostBreakdown) {
        this.scenarioCostBreakdown?.forEach(c => {
          cost += c.credits;
        });
        return cost;
      } else {
        return null;
      }
    },
    creditType() {
      if(this.simulation?.simulation_type === 'ML') {
        return 'AI';
      } else if (this.simulation?.simulation_type === 'CFD') {
        return 'CFD';
      } else {
        return null;
      }
    },
    costBreakdownTableFields() {
      let fields = [{
        key: 'paymentMethod',
        thClass: 'text-left',
        tdClass: 'text-left'
      }, {
        key: 'credits',
        thClass: 'text-center',
        tdClass: 'text-center'
      }, {
        key: 'amount',
        thClass: 'text-right',
        tdClass: 'text-right'
      }];
      return fields;
    },
    costBreakdownTableObject() {
      let monthlyCost = {
        paymentMethod: 'subscription credits',
        credits: 0,
        amount: '-'
      };
      let prepaidCost = {
        paymentMethod: 'pay-per-use (prepaid credits)',
        credits: 0,
        amount: '-'
      };
      let chargedCost = {
        paymentMethod: 'pay-per-use (charged credits)',
        credits: 0,
        amount: null
      };
      let costBreakdown = [];
      if(this.scenarioCostBreakdown) {
        this.scenarioCostBreakdown.forEach(cost => {
          if (cost.subscription_type === 'MONTHLY') {
            monthlyCost.credits = cost.credits;
          } else if (cost.subscription_type === 'ONE-TIME' && cost.subscription_id) {
            prepaidCost.credits += cost.credits;
          } else if (cost.subscription_type === 'ONE-TIME' && !cost.subscription_id) {
            chargedCost.credits = cost.credits;
            let creditCost = this.companySubscriptionTypePrices.find(price => price.subscription_type.credit_type === this.creditType && price.subscription_type.tier === 'Pay-per-use').price;
            chargedCost.amount = `$${cost.credits * creditCost}`;
          }
        });
        if(monthlyCost.credits) costBreakdown.push(monthlyCost);
        if(prepaidCost.credits) costBreakdown.push(prepaidCost);
        if(chargedCost.credits) costBreakdown.push(chargedCost);
        return costBreakdown;
      } else {
        return null;
      }
    },
    scenarioDollarCost() {
      if(this.scenarioCostBreakdown) {
        let chargedCost = this.scenarioCostBreakdown.find(cost => !cost.subscription_id);
        if(chargedCost) {
          let creditCost = this.companySubscriptionTypePrices.find(price => price.subscription_type.credit_type === this.creditType && price.subscription_type.tier === 'Pay-per-use').price;
          return chargedCost.credits * creditCost;
        }
      }
      return 0;
    },
    canDownloadSimulationSettings() {
      if (this.loggedInUser?.is_superuser || this.userCompany?.is_rwdi)
        return true;
      else
        return false;
    },
    ...mapGetters([
      'simulationPrices',
      'loggedInUser',
      'userCompany',
      'companyAvailableSubscriptions',
      'companySubscriptionTypePrices',
      'scenarioCostBreakdown']),
    ...mapGetters('project/simulationAsset', ['simulation']),
    ...mapGetters('project', ['jobTypes']),

  },
  data() {
    return {
      formValues: {},
      costBreakdownErrorModalOpen: false,
      loadingCostBreakdown: false,
      errorSubmittingScenario: false
    };
  },
  methods: {
    async downloadSimulationSettings() {
      await fileDownloadService.getSimulationSettingsPDF(this.simulation.id);
    },
    closeErrorModal() {
      this.costBreakdownErrorModalOpen = false;
    },
    async submitScenario() {
      if(this.importResultJobType){
        try {          
          await this.$store.dispatch('project/importResults', {
            projectId: this.importResultsObject.projectId,
            gltfData: { gltfData: this.importResultsObject.gltfData }
          });
          this.$emit('handle-submitted-scenario');
          return true;
        } catch (error) {
          console.error(error);
          return false;
        }  
      }else{
        try {
          await this.$store.dispatch('project/submitScenario', {
            ...this.scenarioSubmissionObject
          });
          this.$emit('handle-submitted-scenario');
        } catch(error) {
          if(error.response?.data?.isCostBreakdownError) {
            await this.$store.dispatch(GET_SCENARIO_COST_BREAKDOWN, this.scenarioCostBreakdownObject);
            this.costBreakdownErrorModalOpen = true;
          } else {
            this.errorSubmittingScenario = true;
          }
        }
      }
    }
  },
  watch: {
    async showModal(newValue) {
      if(newValue) {
        this.loadingCostBreakdown = true;
        await this.$store.dispatch(GET_SCENARIO_COST_BREAKDOWN, this.scenarioCostBreakdownObject);
        this.loadingCostBreakdown = false;
      }
    }
  }
};
</script>

<style>

.submit-scenario-checkbox .formulate-input-element--checkbox {
  overflow: unset !important;
}

.submit-scenario-checkbox-label {
  margin-left: 0.5em;
}
</style>
