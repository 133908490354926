import axios from 'axios';
import projectApiService from '@/network/project';

export default {
  async downloadFile(url, filename) {

    try {
      let config = {
        responseType: 'blob',
      };

      const instance = axios.create(config);

      var response = await instance.get(url, config);
        
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.download = filename;

      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (ex) {
      console.log(ex);
    }
     
 
  },
  async getSimulationSettingsPDF(simulationId) {

    const response = await projectApiService.getSimulationSettingsPDF(simulationId);
    // Create a Blob from the PDF Stream
    const file = new Blob([response], { type: 'application/pdf' });

    // Create a link to download the file
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download', 'downloaded.pdf'); // or any other filename
    document.body.appendChild(link);
    
    link.click();
    document.body.removeChild(link); // Clean up

  },

};